import React from 'react';
import {Trans, useTranslation} from "gatsby-plugin-react-i18next";
import CustomLocalizedLink from "../locales/custom-localized-link";

const BookConsultation = () => {
  const {t} = useTranslation();
  return (
          <div className="sl-buttons">
            <CustomLocalizedLink
                label={t('book_consultation')}
                goto={'/contact/consultation/'}
                cls={'btn btn--orange page-brochure-button book-consultation'} />
          </div>
  )
}
export default BookConsultation;